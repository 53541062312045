@media (max-width: 576px) {

.bottom-navbar {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
  }
  
  .bottom-navbar a {
    float: left;
    display: block;
    color: #080808;
    text-align: center;
    padding: 14px 18px;
    text-decoration: none;
    font-size: 12px;
    width: 20%;
  }
  .bottom-navbar>a>span{
    position: absolute;
    background: #f36e36;
    color: #fff;
    /* top: 1px; */
    font-weight: 400;
    /* right: 0px; */
    /* text-align: center; */
    border: 3px solid #f36e36;
    font-size: 9px;
    min-width: 15px;
    line-height: 0px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 41px;
    margin-bottom: -35px;
    margin-top: -24px;
  }
  
  .bottom-navbar a:hover {
    background: #f1f1f1;
    color: black;
  }
  
 
}