
:root {
    --color-bg: #ffffff; 
    --color-bar: #ccc;
    --gradient: linear-gradient( 72deg, var(--color-bar), var(--color-bg) );
  }
  
  .placeholder {
    position: relative;
    font-size: 16px; /* size of placeholder */
    background-image: var( --gradient );
    background-size: 400% 400%;
    animation: loading 1.5s ease-in-out infinite;
    
    /* hide inner text */
    text-indent: -9999px;                 
    white-space: nowrap;  
    overflow: hidden;
      
    /* optional centering */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .placeholder-pro {
    position: relative;
    font-size: 16px; /* size of placeholder */
    background-image: var( --gradient );
    background-size: 400% 400%;
    animation: loading 1.5s ease-in-out infinite;

  }
  
  
  @keyframes loading { 
    0% { background-position: 70% 0%; }
    50% { background-position: 30% 100%; }
    100% { background-position: 70% 0%; }
  }

  .place-header-section{
      width: 100%;
  }
  .place-slider{
    width: 100%;
    height: 500px;
}
.place-product{
    overflow: hidden;
    box-shadow: 0 0 6px 3px hsl(0deg 0% 60% / 25%);
    border: 0;
    transition: all .5s ease;
    padding: 10px;
    
}
.place-title-text{
    width: 100%;
    height: 20px;
}
.place-price{
    width: 40%;
    height: 15px;
}
.place-feature-img{
    height: 475px!important;
}
.top-padding{
    position: relative;
    top: 110px;
}
.top-padding2{
    position: relative;
    top: 150px;
}
.place-social-links a {
    color: #131212;
    display: inline-block;
    text-align: center;
    width: 30px;
}
.place-helpline {
    color: #131212;
    font-family: "Heebo";
    float: right;
}
.place-brand-logo{
    height: 50px;
    width: 175px;
    line-height: 100px;
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
}
.place-brand-menu{
    height: 30px;
    width: 330px;
    line-height: 100px;
    margin: 25px 0px;
    margin-right: 15px;
    float: left;
}
.place-brand-search{
    height: 30px;
    width: 80%;
    line-height: 100px;
    margin: 25px 0px;
    margin-right: 15px;
    float: left;
}
.place-brand-user{
    width: 70px;
    height: 70px;
}
.place-social {
    width: 150px;
    height: 20px;
    margin-top: 8px;
}

.em-place-body {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .em-place-body img {
    width: 290px;
    height: 125px;
  }

@media (max-width: 576px) {

    .top-padding2 {
        top: 120px;
    }
    .place-brand-menu{
        display: none;
    }
}