/* Template */

.floating-message {
    position: fixed;
    background: #000;
    left: 10px;
    bottom: 12px;
    width: 300px;
    border-radius: 3px;
    color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
    height: 50px;
    z-index: 2001;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: nowrap;
}

.floating-message .cell {
    flex: 0 1 auto;
}

.floating-message .cell-fill {
    flex: 1 1 auto;
}

.floating-message .cell-self-middle {
    align-self: center;
}

.floating-message>span {
    font-weight: 300;
    font-size: 14px;
}

.floating-message>button {
    background: #000;
    border: 0px solid;
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
}

.floating-message>button:hover {
    opacity: 1;
}

.floating-message>button>svg {
    color: rgba(255, 255, 255);
    width: 25px;
    height: 25px;
    display: block;
}

.alert-backdrop {
    background: #00000099;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    z-index: 2000;
    top: 0;
    left: 0;
    justify-content: center;
}

.alert-backdrop>.alert-confirm {
    align-self: center;
    background-color: #fff;
    border-radius: 30px;
    width: 450px;
    height: 220px;
}

.alert-backdrop>.alert-confirm .body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
    text-align: center;
}

.alert-backdrop>.alert-confirm div.icon {
    margin-top: 30px;
}

.alert-backdrop>.alert-confirm div.text {
    text-align: center;
}

.alert-backdrop>.alert-confirm div.button-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}

.alert-backdrop>.alert-confirm div.button-area>button {
    margin: 10px 10px 36px 10px;
    background: linear-gradient( 180deg, rgba(194, 255, 213, 0.1) 0%, rgba(23, 220, 105, 0.1) 100%);
    border-radius: 12px;
    font-size: 14px;
    line-height: 21px;
    color: #17dc69;
    border: 1px solid #17dc69;
}

.alert-backdrop>.alert-confirm div.button-area>button.delete {
    background: linear-gradient(180deg, #ffccde 0%, #f50057 100%);
    box-shadow: 5px 5px 35px rgba(255, 226, 238, 0.12);
    color: #ffffff;
    border: 0 solid #e6e9e7;
}