/* Cart Section */

.cart-product-list {
    padding-top: 20px;
    border-bottom: 1px solid #e5e6e8;
    padding-bottom: 20px;
}

.cart-product-list:last-child {
    margin-bottom: 10px;
    border-bottom: none;
}

.cart-product-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cart-product-flex>a {
    width: 100%;
    margin-bottom: 5px;
}

.cart-section {
    padding-top: 130px;
    background: #f3f3f3;
    padding-bottom: 20px;
}

.cart-section-card {
    min-height: 600px;
}

.cart-section-product {
    min-height: 100px;
    text-align: center;
}

.cart-section-product-top {
    text-align: left;
    margin: auto;
    padding: 10px;
    border-bottom: 1px solid #96969687;
    display: flex;
}
.cart-section-product-top>h6 {
    width: 80%;
}
.cart-section-product-top>button {
    width: 20%;
    border: none;
    background: none;
    text-align: right;
}
.cart-section-product-list {
    padding: 11px;
}

.cart-section-product-img {
    float: left;
}

.cart-section-product-img>img {
    width: 83px;
    object-fit: contain;
}

.cart-section-summary>table>tbody>tr {
    border-style: hidden!important;
}

.disabled-link {
    pointer-events: none;
}

.cart-product-qty {
    width: 117px;
    height: 40px;
    border: 1px solid #e5e6e8;
    box-sizing: border-box;
    text-align: center;
    padding-top: 7px;
}

.cart-product-dlt-btn {
    position: absolute;
    right: 30px;
}

.variant-list-cart {
    display: flex;
    align-items: center;
}

.cart-color-item-bg {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    background-image: url("../images/IconCheckBoxCorrect.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
}

.cart-detail-sizeBtn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    background-color: #dc3545;
    font-size: 10px;
    margin-right: 10px;
    color: white;
}

.cart-detail-ageBtn {
    font-size: 10px;
    border: none;
    padding: 2px 10px 2px 10px;
    border: 1px solid #dc3545;
    border-radius: 10px;
    background: none;
    color: #dc3545;
}

.cart-checkbox {
    padding-left: 25px;
}

.cart-product-qty>input {
    border: none;
    width: 50px;
    text-align: center;
}
.checkout-summary-title{
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #00071B;
    border-bottom: 1px solid #96969687;
    padding-bottom: 17px;
    margin-bottom: 20px;
}
.checkout-summary-top{
    display: flex;
    height: 25px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.checkout-summary-divider{
    border-bottom: 1px solid #96969687;
    margin-bottom: 20px;

}
.checkout-summary-top>p{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    width: 75%;
}
.checkout-summary-top>button{
    width: 25%;
    border: none;
    background: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    text-transform: uppercase;
    color: #4DA9FF;
}
.checkout-summary-address{
    display: flex;
    flex-wrap: wrap;
}
.checkout-summary-address>p{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #c1c2c7
}
.checkout-summary-note{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #FE0002;
    position: relative;
    bottom: 10px;
    list-style: circle;
}
.btn-set-email{
    border: none;
    background: red;
    color: white;
}
.address-type-btn{
    width: 70px;
    height: 30px;
    background: linear-gradient(154.38deg, #FF8BBC 2.32%, #FF284F 83.79%);
    border-radius: 15px;
    border: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    margin-right: 10px;
}
.address-type-btn>img{
    margin-right: 5px;
    padding-bottom: 3px;

}
.checkout-summary-address-btn{
    width: 100%;
    border: none;
    height: 50px;
    background-color: #FFE5E6;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FE0002;
}
@media (max-width: 576px) {
    .cart-product-flex>a {
        font-size: 12px;
    }
    .cart-checkbox {
        padding-left: 0;
    }
    .cart-product-qty {
        width: 100%;
    }
    .cart-qty {
        padding: 0;
    }
    .cart-product-qty>svg {
        width: 7px;
    }
    .cart-product-qty>input {
        width: 35px;
    }
    .cart-product-dlt-btn {
        right: 1px;
        font-size: 12px;
    }
    .cart-qty>small {
        font-size: 12px;
    }
    .cart-detail-ageBtn {
        font-size: 9px;
    }
    .cart-section-card {
        min-height: 60vh;
    }
    .cart-section-product-top>h6{
        width: 70%!important;
    }
    .cart-section-product-top>button{
        width: 30%!important;
    }
    .checkout-summary-top>p {
        width: 70%!important;
    }
    .checkout-summary-top>button{
        width: 30%!important;
    }
}