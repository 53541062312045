.top-options {
    height: 80px;
    float: right;
    display: block;
    text-align: center;
    width: 77px;
    position: relative;
}

.top-options>.top-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    height: 100%;
    color: #fff;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: center;
}

.top-options>.top-icon:hover,
.top-options>.top-icon.box-showing {
    background-color: #00000015;
}

.top-options>.top-icon>div {
    font-family: "Noto Sans SC";
    font-weight: 300;
    margin-top: 6px;
    font-size: 14px;
}
.top-icon>img{
    width: 30px;
    height: 30px;
}
.top-icon>span{
    width: 30px;
    height: 30px;
}
.top-icon>svg{
    width: 30px;
    height: 30px;
}