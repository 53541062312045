/* Flash Product Section */

.flash-product-section {
    background-color: #f3f3f3;
    padding-top: 10px;
    padding-bottom: 20px;
}

.flash-product-section .container {
    background-color: white;
}

.flash-product-section .ends-title {
    height: 100%;
    display: flex;
    align-items: center;
}

.flash-product-section .view-all {
    height: 100%;
    display: flex;
    align-items: center;
}

.flash-product-section .view-all>span {
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 15px;
}

.flash-product-section .card {
    border: 0;
    transition: all 0.5s ease;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 13px -6px #77777760;
}

.flash-product-section .card:hover {
    box-shadow: 0 0 14px 4px #77777760;
}

.flash-product-section .card-body {
    padding: 0.1rem;
}

.flash-productItem {
    padding-bottom: 10px;
}

.flash-product-section .card-text {
    font-size: 13px;
    line-height: 17px;
    padding-left: 5px;
    margin-bottom: 2px;
    height: 35px;
    margin-top: 4px;
    overflow: hidden;
}

.flash-product-section .card-text>a {
    color: black;
}

.flash-product-section-imgBox {
    cursor: pointer;
    height: 120px;
    object-fit: cover;
    object-position: top;
    border-radius: 0;
}

.flash-product-section .pricing {
    font-size: 16px;
    padding-left: 6px;
    margin-bottom: 15px;
}

.flash-product-section .pricing>span.main-price {
    text-decoration-line: line-through;
    font-size: 12px;
}

.flash-product-section .pricing>span.offer {
    font-size: 12px;
    padding-left: 5px;
    font-weight: bold;
}

.flash-product-section .pricing>strong {
    font-size: 14px;
}

@media (max-width: 576px) {
    .sm-padding {
        margin: 0 0px;
    }
    .col-in-2-sm {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        padding-left: 10px;
        padding-right: 10px;
    }
    .flash-product-section .view-all {
        min-height: 50px;
    }
}