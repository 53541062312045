/*User Section*/

.user-section {
    background-color: #f3f3f3;
    padding-top: 142px;
}

.user-title {
    margin-top: 10px;
    padding-bottom: 10px;
}

.user-title-view {
    background: #fff;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #9993;
    margin-bottom: 10px;
}

.user-title-view>h6 {
    font-size: 20px;
    padding: 10px;
}

.UserColI5 {
    flex: 0 0 25%;
    max-width: 25%;
}

.UserDashboardsTitleText {
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
    display: inline-block;
}

.UserDashboardsTitleText>span {
    border-bottom: 3px solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

.user-card {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
    margin-bottom: 1.5rem !important;
}

.user-card:hover {
    box-shadow: 0 0 6px 6px #77777760;
}

.UserCardBody {
    padding: 0.5rem;
}

.UserDashboardPageBanner {
    height: 100px;
    text-align: center;
}

.UserDashboardPageBanner>h5 {
    text-align: center;
    margin: auto;
    padding-top: 40px;
}

.user-orders-number {
    font-size: 12px;
}

.user-orders-time {
    font-size: 10px;
}

.user-Orders-manage {
    font-size: 12px;
    float: right;
    vertical-align: top;
}

.user-orders-img {
    float: left;
}

.user-orders-img>img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.UserOrdersTitle {
    /*  */
}

.UserOrdersQty {
    /*  */
}

.UserOrdersPrice {
    /*  */
}

.UserOrdersStatus {
    /*  */
}

.user-menu {
    padding: 10px;
}

.user-menu> :hover>a {
    color: #00ab55;
}

.user-menu>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}

.user-menu-link {
    text-decoration: none;
    color: black;
}

.user-menu>ul>li>a {
    line-height: 31px;
    color: black;
}

.user-menu>ul>li:hover>a {
    color: #00ab55;
}

.user-menu>ul>li>a>.scustom-bullet {
    width: 24px;
    height: 24px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 17px;
    left: 12px;
    margin-right: 10px;
}

.user-menu>ul>li>a>.scustom-bullet:before {
    width: 4px;
    height: 4px;
    content: "";
    display: block;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    background-color: #919eab;
}

.user-menu>ul>li:hover>a>.scustom-bullet:before {
    transform: scale(2);
    background-color: #00ab55;
}

.UserDashboardOrder {
    padding: 10px;
}

.UserDashboardOrder>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
    padding-left: 30px;
}

.UserDashboardReview {
    padding: 10px;
}

.UserDashboardReview>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
    padding-left: 30px;
}

.UserDashboardWish {
    padding: 10px;
}

.UserDashboardWish>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
}

.custom-login-content {
    width: 420px !important;
    height: auto!important;
    background: #ffffff !important;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04) !important;
    padding: 7px 15px 15px 15px;
    margin: auto;
}

.custom-login-content>.modal-header {
    border-bottom: none !important;
}

.custom-reg-content {
    width: 450px !important;
    background: #ffffff !important;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04) !important;
    padding: 7px 15px 15px 15px;
    margin: auto;
}

.custom-reg-content>.modal-header {
    border-bottom: none !important;
}

.custom-profile-change-content {
    width: 450px !important;
    height: auto;
    background: #ffffff !important;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04) !important;
    padding: 7px 15px 15px 15px;
    margin: auto;
}

.custom-profile-change-content>.modal-header {
    border-bottom: none !important;
}

.btn-close-custom .btn-close {
    width: 30px;
    height: 30px;
    background: rgba(255, 27, 40, 0.1);
    border: 1px solid #fbcfd3;
    box-sizing: border-box;
    border-radius: 30px;
    background-image: url("../images/IconCross.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    bottom: -3px;
}

.custom-login-input {
    margin-bottom: 14px;
}

.custom-login-other {
    margin-bottom: 14px;
}

.custom-login-other>a {
    float: right;
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00a3ff;
}

.custom-login-other-label>label {
    float: right;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #535f82;
    position: relative;
    top: 3px;
}

.custom-label {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.9;
}

.custom-input {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgb(0 7 27 / 10%);
    box-sizing: border-box;
}

.custom-input:focus {
    border: 1px solid rgb(235, 33, 33);
    background: none!important;
}

.custom-input-otp {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E5E6E8;
    box-sizing: border-box;
    margin-bottom: 20px;
    letter-spacing: 20px;
    font-size: 30px;
}

.custom-input-otp::-webkit-outer-spin-button,
.custom-input-otp::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.custom-input-otp[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.custom-input-otp:focus {
    border: 1px solid rgb(235, 33, 33);
    background: none!important;
}

.custom-otp-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.9;
    margin-bottom: 20px;
}

.custom-otp-expire {
    margin-bottom: 11px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00071B;
    margin-bottom: 20px;
}

.custom-otp-expire>b {
    color: red;
}

.custom-verify-btn {
    width: 100%;
    height: 50px;
    background: #FE0002;
    border: none;
    margin-bottom: 20px;
    color: white;
}

.custom-resend-btn {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E5E6E8;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-resend-btn>p {
    width: 90%;
    color: #FE0002;
    text-align: center;
    height: 13px;
}

.custom-resend-btn>div {
    width: 26px;
    height: 26px;
    border: 1px solid rgba(254, 0, 2, 0.5);
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    color: #FE0002;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-resend-btn:disabled p {
    color: #E5E6E8;
}

.custom-login-btn {
    width: 100%;
    height: 50px;
    border: none;
    background: #fe0002;
    margin-bottom: 14px;
}

.custom-login-btn:hover {
    background: #ce0406 !important;
}

.custom-login-social {
    margin-bottom: 24px;
}

.custom-login-btn-google {
    width: 30%;
    height: 50px;
    background: #ea4335;
    margin-right: 5%;
    border: none !important;
}

.custom-login-btn-facebook {
    width: 30%;
    height: 50px;
    background: #1877f2;
    margin-right: 5%;
    border: none !important;
}

.custom-login-btn-twitter {
    width: 30%;
    height: 50px;
    background: #1da1f2;
    border: none !important;
}

.custom-login-btn-google:hover {
    background: #c2382c;
}

.custom-login-btn-facebook:hover {
    background: #1468d4;
}

.custom-login-btn-twitter:hover {
    background: #178dd6;
}

.custom-login-footer>p {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #1a2032;
}

.custom-login-footer>p>span {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #fe0002;
    cursor: pointer;
}

.user-support-section {
    width: 100%;
}

.user-support-section>table {
    width: 100%;
}

.support-create-btn {
    border: none !important;
    margin-left: 10px;
    border-radius: 7px;
    padding: 5px 10px 5px 10px;
}
.user-support-section{
    min-height: 100px;
    padding: 20px;
}
.user-support-title{
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.user-section-title>h6{
    float: left;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #00071B;
    opacity: 0.7;
}
.user-support-body{
    width: 100%;
}
.user-support-body>table{
    width: 100%;
}
.user-support-body>table>thead{
    height: 50px;
    background: #EBEBED;
}
.user-support-body>table>thead>tr>th{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.8);
    background-image: url('../images/title-devider.svg');
    background-repeat:no-repeat;
    background-position: left;
    text-align: center;

}
.user-support-body>table>thead>tr>th:first-child{
    padding-left: 10px;
    background-image: none;
}
.user-support-body>table>tbody>tr{
    height: 43px;
    border-bottom: 1px solid #F2F2F3;
}
.user-support-body>table>tbody>tr>td{

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.7;
    text-align: center;

}
.user-support-body>table>tbody>tr>td:first-child{
    padding-left: 10px;
}
.user-support-section-sidebar-title{
    border: none!important;
    background-color: #F2F3F4!important;
    border-radius: 50px;
}
.user-support-section-sidebar-title>li{
    width: 50%;
    height: 40px;
}
.user-support-section-sidebar-title>li>button{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #00071B;
    opacity: 0.5;
    height: 100%!important;
}
.user-support-section-sidebar-title>li>button:hover{
    border: none!important;
    color: #00B59C!important;
    height: 100%!important;
}
.user-support-section-sidebar-title>li>.active{
    border: none!important;
    color: #00B59C!important;
    background-color: #ced4da!important;
    border-radius: 50px;
    width: 100%;
}

.user-wish-title {
    display: flex;
    margin-bottom: 30px;
}

.user-wish-title-view {
    margin: auto;
    width: 381px;
    height: 50px;
    display: inline-grid;
    grid-template-columns: 50% 50%;
    background-color: #f1f2f3;
    border-radius: 50px;
}

.user-wish-title-view>a {
    background-color: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838d;
    padding-top: 7%;
}

.user-wish-title-view>a:hover {
    color: red;
}

.user-wish-btn-active {
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}

.user-wish-title-view>button {
    background-color: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838d;
    border: none;
}

.user-wish-title-view>button:hover {
    color: red;
}
.user-review-btn{
    width: 117px;
    height: 40px;
    background: #FFF6E8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-review-btn>a{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FFA41D;
}
.user-review-btn:hover{
    background: #FFA41D;
}
.user-review-btn:hover a{
    color: white;
}
.user-review-detail{
    background-color: #FFFFFF;
    padding: 50px;
    min-height: 150px;
}
.user-review-detail-title{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.5);
    opacity: 0.8;
    padding-bottom: 14px;
    border-bottom: 1px solid #EEEFF1;
    margin-bottom: 20px;
}
.user-review-detail-info{
    
}
.review-ratting{
    margin-bottom: 20px;
}
.review-text-box{
    margin-bottom: 20px;
}
.review-text-box>lable{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.7;
}
.review-text-box>lable>span{
    float: right;
    color: #00B59C;
}
.review-text-box>textarea{
    width: 100%;
    height: 100px;
    border-color: #D9D9DD;
    padding: 10px;
    color: #a8a8af;
}
.review-img-box{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.review-img-box>img{
    width: 16%;
    margin-right: .60%;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
}
.review-notice-box>p{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
}
.review-notice-box>ul>li{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #00071B;
    opacity: 0.7;
}
.review-submit{
    width: 130px;
    height: 50px;
    background: #FE0002;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    float: right;
    border: none;
}
@media (max-width: 576px) {
    .custom-login-content {
        width: 100% !important;
    }
    .custom-reg-content {
        width: 100% !important;
    }
    .user-voucher-section{
        padding: 0!important;
    }
    .user-voucher-section-pt-20{
        padding-top: 20px!important;
    }
}