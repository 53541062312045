img{ max-width:100%;}
.inbox_people {
  float: left;
  overflow: hidden;
  width: 30%;
  margin-right: 2%;
}
.inbox_msg {
  clear: both;
  overflow: hidden;
  height: 474px;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{  overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ 
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #00071B;

  opacity: 0.7;
}
.chat_ib h5 span{ 
  margin-top: 10px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;
  color: #00071B;
  opacity: 0.7;
  float:right;
}
.chat_ib p{ 
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #00071B;
  margin-bottom: 0!important;
}
.chat_ib span{ 
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 7, 27, 0.7);

}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  width: 95%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 5px 10px 5px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 15%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 85%;
 }
 .received_withd_msg p {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px rgba(0, 7, 27, 0.05);
  border-radius: 22.5px;
  color: rgba(0, 7, 27, 0.7);
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 0px 0 0 10px;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 15px 15px 0 25px;
  width: 68%;
  background-color: #F3F6F9;
  height: 100%;

}

 .sent_msg p {
  background: #00B59C;
  box-shadow: 0px 4px 50px rgba(0, 7, 27, 0.05);
  border-radius: 22.5px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  position: relative;
  background-color: #FFFFFF!important;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 1px 0;}
.msg_history {
  height: 392px;
  overflow-y: auto;
}

.profile-user-circle-chat {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: #1d8aff;
  margin: auto !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.profile-user-circle-chat>span {
  font-size: 20px !important;
}
.img-with-btn { 
  position: absolute;
  width: 45px;    
  height: 100%;
  display: flex;
  align-items: center;
}
.img-with-btn img { display: block;     width: 40px;
  height: 30px;
}
.img-with-btn button { 
  position: absolute;
  bottom: 34px;
  left: 32px;
  background: none;
  border: none; 
}
.incoming_msg{
  margin: 26px 0 26px;
}
.msg-input-group {
  width: 98%;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  display: flex;

}
.msg-input-group > input {
  background: none;
  border: none;
  width: 86%;
  padding-left: 20px;
  outline: none !important;
  color: #B3B5BB;
  opacity: 0.7;
}
.msg-input-group > button {
  width: 40px;
  height: 40px;
  border: none;
  margin: auto;
  outline: none;
}
.msg-input-attaBtn{
  background-color: #FFFFFF!important;
  background-image: url("../images/ClipIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.msg-input-orderBtn{
  background-color: #FFFFFF!important;
  background-image: url("../images/LocakerIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.msg-input-sentBtn{
  background-color: #00B59C!important;
}