.user-profile {
    background: #ffffff;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding-top: 50px;
    padding-bottom: 20px;
    min-height: 700px;
    margin-bottom: 30px;
}

.user-profile-title {
    margin-left: 20%;
}
.user-dashboard-setting-icon::after {
    display: none !important;
}
.user-profile-title-view {
    margin: auto;
    width: 570px;
    height: 50px;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    background-color: #f1f2f3;
    border-radius: 50px;
}

.user-profile-title-view > a {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838d;
    border: none;
    padding-top: 13px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.user-profile-title-view > a:hover {
    color: red;
}

.user-profile-btn-active {
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}

.user-profile-show {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 45px;
}

.user-profile-show-title {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.8;
}

.user-profile-show-data {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.3;
}

.user-profile-show-data > input {
    background: none;
    border: none;
    width: 100%;
}

.user-profile-show-data > input:focus {
    background: none;
    outline: none;
}

.user-profile-show-btn {
    float: right;
    width: 100px;
    height: 35px;
    background: #e5e6e8;
    text-align: center;
}

.user-profile-show-btn > button {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071b;
    opacity: 0.5;
    position: relative;
    top: 10%;
    border: none;
    background: none;
}

.user-profile-show-divider {
    height: 1px;
    background: #d3d2db;
    mix-blend-mode: normal;
}

.user-profile-family {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 45px;
}

.user-profile-family-member {
    border: 1px solid #e5e6e8;
    height: 171px;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 10px;
}

.user-profile-family-member-img {
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 15px;
}

.user-profile-family-member-img > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.user-profile-family-member-type {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    text-align: center;
}

.user-profile-family-member-detail {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: left;
    padding-left: -15px;
}

.user-profile-family-member-value {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.3;
    padding-left: -15px;
}

.user-profile-address {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 45px;
}

.user-profile-address-thead {
    background: #f2f2f3;
}

.user-profile-address-tbody {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9fa2a9;
}

.user-address-td-detail {
    display: inline-grid;
    grid-template-columns: auto auto;
}

.user-address-edit-btn {
    width: 70px;
    height: 35px;
    background: #ffe5e6;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #fe7374;
    padding: 5px 10px 5px 10px;
    border: none;
}
.user-address-radio-check > .form-check-input {
    width: 15px !important;
    height: 15px !important;
    border: 1px solid #bdbec3 !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
}
.user-address-radio-check > .form-check-input:checked {
    width: 15px !important;
    height: 15px !important;
    background-color: red !important;
    border-radius: 0 !important;
    border: none !important;
    background-image: url('../images/IconCheckBoxCorrect.svg') !important;
    background-size: auto !important;
}
.address-submit-btn {
    width: 150px;
    height: 50px;
    background: #e5f8f5;
    border: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00b59c;
}
.address-cancel-btn {
    width: 130px;
    height: 50px;
    background: #ffe5e6;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #fe0002;
    border: none;
    margin-right: 20px;
}
.address-modal-content {
    padding: 27px 25px 25px 25px;
}
.address-modal-content > .modal-header {
    border-bottom: none !important;
}
.address-modal-content > .modal-header > .modal-title > button {
    right: 4px !important;
}
.address-modal-table {
    width: 100% !important;
}
.address-modal-table > thead {
    width: 100% !important;
    height: 50px !important;
    background-color: #f0f0f1 !important;
}
.address-modal-table > tbody > tr {
    border-bottom: 1px solid #f0f0f1;
}
.address-modal-table > tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
}
.address-back-btn {
    background: none;
    border: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4da9ff;
    border-left: 1px solid black;
    margin-left: 15px;
    padding-left: 15px;
}

.user-menu-section {
    background-color: #ffffff;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    height: 700px;
    margin-bottom: 30px;
}

.user-menu-profile {
    padding-top: 50px;
}

.user-menu-profile-img {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 20px;
}

.user-menu-profile-img > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.user-menu-profile-title {
    margin: auto;
    text-align: center;
}

.user-menu-profile-title > h3 {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #00071b;
}

.user-menu-profile-title > p {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00071b;
    opacity: 0.5;
}

.user-menu-profile-btn {
    margin: auto;
    text-align: center;
    width: 146px;
    height: 40px;
    background: #fe0002;
    margin-bottom: 20px;
}

.user-menu-profile-btn > a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    position: relative;
    top: 12%;
}

.user-menu-profile-divider {
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(19, 15, 64, 0.101961) 0%,
        #130f40 33.3%,
        #130f40 66.6%,
        rgba(19, 15, 64, 0.101961) 100%
    );
    mix-blend-mode: normal;
    opacity: 0.2;
    margin-bottom: 30px;
}
.user-profile-menu {
    padding: 0 15px 0 15px;
}
.user-menu2 {
    padding-top: 15px;
    padding-bottom: 3px;
    padding-left: 15px;
    display: flex;
    align-self: center;
    height: 50px;
}
.user-menu2:hover {
    background-color: #fff2f2;
}
.user-menu2-active {
    padding-top: 15px;
    padding-bottom: 3px;
    padding-left: 15px;
    display: flex;
    align-self: center;
    height: 50px;
    background-color: #fff2f2;
}
.user-menu-order-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconOrder.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}
.user-menu-favt-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconFavorite.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}
.user-menu-review-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconReview.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}

.user-menu-voucher-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconReward.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}

.user-menu-points-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconCoin.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}
.user-menu-support-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconReview.svg');
    background-repeat: no-repeat;
    margin-right: 15px;
}
.user-menu-order-text {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}
.user-menu-favt-text {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}
.user-menu-review-text {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}
.user-menu-support-text {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}
.user-menu2-active > .user-menu-order-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconOrderC.svg');
}
.user-menu2:hover > .user-menu-order-img {
    width: 25px;
    height: 25px;
    background-image: url('../images/IconOrderC.svg');
}
.user-menu2-active > .user-menu-favt-img {
    background-image: url('../images/IconFavoriteC.svg');
}
.user-menu2:hover > .user-menu-favt-img {
    background-image: url('../images/IconFavoriteC.svg');
}
.user-menu2-active > .user-menu-review-img {
    background-image: url('../images/IconReviewC.svg');
}
.user-menu2:hover > .user-menu-review-img {
    background-image: url('../images/IconReviewC.svg');
}

.user-menu2-active > .user-menu-voucher-img {
    background-image: url('../images/IconRewardC.svg');
}
.user-menu2:hover > .user-menu-voucher-img {
    background-image: url('../images/IconRewardC.svg');
}

.user-menu2-active > .user-menu-points-img {
    background-image: url('../images/IconCoinC.svg');
}
.user-menu2:hover > .user-menu-points-img {
    background-image: url('../images/IconCoinC.svg');
}
.user-menu2-active > .user-menu-support-img {
    background-image: url('../images/IconReviewC.svg');
}
.user-menu2:hover > .user-menu-support-img {
    background-image: url('../images/IconReviewC.svg');
}
.user-menu2:hover > .user-menu-order-text {
    color: red;
}
.user-menu2:hover > .user-menu-favt-text {
    color: red;
}
.user-menu2:hover > .user-menu-review-text {
    color: red;
}
.user-menu2:hover > .user-menu-support-text {
    color: red;
}

.user-menu2-active > .user-menu-order-text {
    color: red;
}
.user-menu2-active > .user-menu-favt-text {
    color: red;
}
.user-menu2-active > .user-menu-review-text {
    color: red;
}
.user-menu2-active > .user-menu-support-text {
    color: red;
}

.user-menu-profile-link {
    text-decoration: none;
    color: black;
}

.user-menu-profile-link > span {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}

.user-menu-profile-link > span:hover {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #fe0002;
    opacity: 0.9;
}

.span-active {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #fe0002 !important;
    opacity: 0.9 !important;
}

.user-menu-profile-link > span > img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.user-order {
    background: #ffffff;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding: 50px;
    min-height: 700px;
    padding-bottom: 6px;
    margin-bottom: 30px;
}

.user-order-title {
    margin-left: 12%;
    margin-bottom: 30px;
}

.user-order-title-view {
    margin: auto;
    width: 570px;
    height: 50px;
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: #f1f2f3;
    border-radius: 50px;
}

.user-order-title-view > a {
    background-color: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838d;
    padding-top: 14px;
}

.user-order-title-view > a:hover {
    color: red;
}

.user-order-btn-active {
    background: #fff;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    color: red !important;
    border-radius: 50px;
}

.user-order-title-view > button {
    background-color: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838d;
    border: none;
}

.user-order-title-view > button:hover {
    color: red;
}
.user-order-section-show-more {
    position: relative;
    bottom: -38px;
    height: 35px;
    left: 45%;
}
.user-order-section-show-more > button {
    border: 1px solid #e5e6e8;
    height: 100%;
    background-color: #ffffff;
    color: #ff8080;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    padding: 0 10px;
}
.user-order-section {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #e5e6e8;
}

.order-section-timeline {
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.order-section-timeline-item {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.order-section-timeline-circle-not-fill {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px dashed #ff0002;
    text-align: center;
}

.order-section-timeline-circle-fill {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fe0002;
    text-align: center;
}

.order-section-timeline-line-not-fill {
    opacity: 0.5;
    border: 1px dashed #fe0002;
    height: 1px;
    position: relative;
    top: 49%;
}

.order-section-timeline-line-blank-fill {
    border: 1px solid transparent;
    height: 1px;
    position: relative;
    top: 49%;
}

.order-section-timeline-line-fill {
    border: 1px solid #fe0002;
    height: 1px;
    position: relative;
    top: 49%;
}

.order-section-timeline-p-m-0 {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.order-section-timeline-line-joint {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(254, 0, 2, 0) 0%, #ffdadb 100%);
    position: absolute;
    top: 229px;
}

.order-section-timeline-line-joint-in {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fe0002;
    position: relative;
    margin: auto;
    top: 25%;
    left: 4%;
}

.order-section-timeline-circle-fill > img {
    max-width: 20px;
    max-height: 20px;
    position: relative;
    top: 20%;
}

.order-section-timeline-circle-not-fill > img {
    /* max-width: 20px;
    max-height: 20px; */
    position: relative;
    top: 20%;
}

.order-section-timeline-title {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00071b;
    opacity: 0.5;
    margin-top: 10px;
}

.user-order-border-bottom {
    border-bottom: 1px solid #e5dede;
    margin-bottom: 10px;
}

.user-order-border-bottom2 {
    border-bottom: 1px solid #e5dede;
    margin-bottom: 10px;
}

.user-order-section-number {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.5);
}

.user-order-section-number > a {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.8;
}

.user-order-section-time {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.3;
}

.user-order-section-btn-detail {
    width: 100px;
    height: 35px;
    background: #e5e6e8;
    border: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #8f929b;
    text-align: center;
    position: relative;
    margin-left: 10px;
    float: right;
}
.user-order-section-btn-detail-pay {
    width: 100px;
    height: 35px;
    background: red;
    border: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    text-align: center;
    position: relative;
    margin-left: 10px;
    float: right;
}
.user-order-section-btn-detail-pay:hover {
    color: white;
}
.user-order-section-btn-review {
    width: 120px;
    height: 35px;
    border: 1px solid #eeeff0;
    box-sizing: border-box;
    background: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6c6f75;
    float: right;
}

.order-section-detail-wishlist {
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.order-section-detail-title {
    margin-left: -15px;
}

.order-section-detail-title > a {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
}

.order-section-status-btn {
    border: none;
    width: 117px;
    height: 40px;
}

.order-estimated-time {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #00b59c;
}

.bottom-border {
    border-bottom: 1px solid #e5dede;
    margin-bottom: 15px;
    padding-bottom: 10px;
    align-items: center;
}

.bottom-border:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}

.order-section-no-order-title {
    margin-bottom: 50px;
}

.order-section-no-order-title > h5 {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #00071b;
    opacity: 0.7;
}

.order-section-no-order-title > h6 {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00071b;
    opacity: 0.5;
}

.order-section-no-order-img {
    margin: auto;
    width: 500px;
}

.order-section-no-order-img > img {
    margin: auto;
}

.order-section-detail {
    padding: 50px;
    border: 1px solid #e5e6e8;
    background: #ffffff;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.order-section-detail-tracking {
    padding: 20px;
    border: 1px solid #e5e6e8;
    margin-bottom: 30px;
}

.order-section-detail-tracking-title {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.5);
    opacity: 0.8;
}

.order-section-detail-tracking-history > ul > li > p {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071b;
    opacity: 0.3;
}

.history-title {
    color: #00071b;
}

.history-title2 {
    color: #00071b;
    opacity: 0.3;
}

.order-section-detail-products {
    border: 1px solid #e5e6e8;
    margin-bottom: 30px;
    padding: 20px;
}

.order-section-detail-info {
    border: 1px solid #e5e6e8;
    margin-bottom: 30px;
    padding: 20px;
}

.order-section-detail-info-title {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.7;
}
.order-border-bottom {
    border-bottom: 1px solid #e5dede;
}
.order-section-detail-info-title > a {
    width: 150px;
}

.order-section-detail-info-data {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #00071b;
    padding-left: 0;
}

.order-section-detail-cancle-btn {
    width: 100px;
    height: 40px;
    left: 859px;
    top: 980px;
    background: #fff2f2;
    border: none;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #fe0002;
}

.order-section-detail-info-address {
    border-bottom: 1px solid #e5e6e8;
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-section-detail-info-payment {
    border-bottom: 1px solid #e5e6e8;
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-section-detail-info-payment-history {
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-section-detail-info-payment-history > table {
    width: 45%;
}

.order-section-detail-info-payment-history > table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
}

.order-section-detail-related-product {
    border: 1px solid #e5e6e8;
    margin-bottom: 30px;
    padding: 20px;
}

.order-section-detail-related-title {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: #00071b;
    border-bottom: 1px solid #e5e6e8;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.order-section-detail-related-product-list {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #00071b;
    padding: 10px;
}

.order-section-detail-related-product-price {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}
.user-voucher-section {
    padding: 50px;
}
.user-voucher-list {
    display: flex;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    height: 130px;
    border-color: #d9dadd;
    background-color: #fffdfd;
    border-width: 2px;
}

.user-voucher-list-amount {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-voucher-list-amount h4 {
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #fe0002;
}

.user-voucher-list-divider {
    width: 5%;
}
.user-voucher-list-divider-line {
    height: 98px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.user-voucher-list-divider-line > .line {
    position: absolute;
    width: 2px;
    height: 75px;
    opacity: 0.2;
    border: 1px dashed #00071b;
}
.top-half-circle {
    width: 25px;
    height: 16px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-color: #d9dadd;
    border-bottom: 0;
    border-style: dashed;
    background-color: #ffffff;
    margin-top: -3px;
    border-top: none;
}

.bottom-half-circle {
    width: 25px;
    height: 17px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-color: #d9dadd;
    border-bottom: 0;
    border-style: dashed;
    background-color: #ffffff;
    margin-bottom: -3px;
    border-top: none;
}
.user-voucher-list-detail {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-voucher-list-detail > div > h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #fe0002;
    opacity: 0.7;
}

.user-voucher-list-detail > div > h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00071b;
    opacity: 0.7;
}

.user-voucher-list-detail > div > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #00071b;
    opacity: 0.5;
}
.user-points-title {
    width: 100%;
    height: 220px;
    background-color: #fff2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-points-title-info {
    width: 80%;
    height: 122px;
    display: flex;
}

.user-points-title-total {
    width: 50%;
}

.user-points-title-total > h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #fe0002;
}

.user-points-title-total > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 73px;
    color: #fe0002;
}

.user-points-title-total > h1 > b {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #fe0002;
}
.user-points-title-daily {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.user-points-title-daily > button {
    border: none;
    width: 140px;
    height: 50px;
    background: #ffffff;
    box-shadow: 8px 10px 30px rgba(0, 15, 55, 0.04);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #fe0002;
}

.user-points-title-daily > button > img {
    margin-right: 10px;
}

@media (max-width: 576px) {
    .user-order-section-btn-detail {
        margin-bottom: 10px;
    }
    .user-order-section-show-more {
        width: 70%;
        left: 32%;
    }
    .address-modal-content {
        padding: 4px;
    }
    .user-dashboard-section {
        width: 100vw;
        height: 105px;
        background-color: #ff0000;
        margin: -12px !important;
        display: flex;
        align-items: top;
        padding-top: 10px;
    }
    .user-dashboard-section-user {
        width: 50%;
        height: 50px;
        background-color: #ff0000;
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
    .user-dashboard-section-user > img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        margin-right: 9px;
    }
    .user-dashboard-section-user > p {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.3px;
        color: #ffffff;
        height: 25px;
    }
    .user-dashboard-section-user > small {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.3px;
        color: #ffffff;
    }
    .user-dashboard-section-setting {
        width: 50%;
        height: 50px;
        padding-right: 15px;
    }
    .user-dashboard-section-setting > a {
        float: right;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .user-dashboard-section-setting > a > img {
        height: 25px;
    }
    .user-dashboard-section-setting > .dropdown {
        float: right;
    }
    .user-dashboard-section-summary {
        height: 80px;
        width: 90vw;
        background-color: #000000;
        margin-left: 2vw;
        position: relative;
        top: -10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 22px;
    }
    .user-dashboard-section-summary > div {
        width: 33.33%;
        text-align: center;
        border-right: 1px solid #585858;
    }
    .user-dashboard-section-summary > div > h6 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #ffffff;
        opacity: 0.9;
    }
    .user-dashboard-section-order {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e7e7e7;
    }
    .user-dashboard-section-order-title {
        display: flex;
        margin-bottom: 20px;
    }
    .user-dashboard-section-order-title > h6 {
        width: 50%;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.3px;
        color: #000000;
    }
    .user-dashboard-section-order-title > a {
        width: 50%;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #ff0000;
        text-align: right;
    }
    .user-dashboard-section-order-option {
        display: flex;
        width: 100%;
        align-items: center;
    }
    .user-dashboard-section-order-option > a {
        width: 33.33%;
    }
    .user-dashboard-section-order-option > a > img {
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .user-dashboard-section-order-option > a > p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        opacity: 0.7;
    }
    .user-dashboard-section-order-option > a:first-child {
        text-align: left;
        width: 40%;
    }
    .user-dashboard-section-order-option > a:last-child {
        text-align: right;
    }
    .user-dashboard-section-order-track {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e7e7e7;
    }
    .user-dashboard-section-package {
        width: 100vw;
        height: 105px;
        margin: -12px !important;
        display: flex;
        padding-top: 10px;
    }
    .user-dashboard-section-package-img {
        width: 30%;
    }
    .user-dashboard-section-package-img > img {
        width: 100px;
        height: 100px;
        margin-right: 5px;
    }
    .user-dashboard-section-package-detail {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-items: top;
        padding-top: 10px;
    }
    .user-dashboard-section-package-detail > p {
        width: 50%;
        margin-bottom: 0 !important;
        height: 18px;
    }
    .user-dashboard-section-package-detail > p > small {
        font-size: 10px;
    }
    .user-dashboard-section-package-detail > span {
        width: 100%;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        opacity: 0.7;
        padding-right: 19px;
    }
    .user-dashboard-section-order-option > .carousel > .carousel-indicators {
        bottom: -30px !important;
    }
    .user-dashboard-section-order-option > .carousel > .carousel-indicators > button {
        width: 5px !important;
        height: 5px !important;
        border-radius: 50% !important;
    }
}
