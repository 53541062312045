/*Cart SideBar Section*/

.top-box {
    z-index: 2;
    opacity: 0;
    width: 300px;
    visibility: hidden;
    right: 5px;
    top: 88px;
    position: absolute;
}

.top-box.show {
    opacity: 1;
    visibility: visible;
}

.top-box:before {
    content: "";
    position: absolute;
    top: -13px;
    right: 16px;
    transition: top 0.35s ease;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
}

.top-box>div {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 3px 9px -1px #999;
}

.top-box-foreground {
    width: 100%;
    height: 100%;
    display: none;
    left: 0;
    top: 0;
    cursor: auto;
    z-index: 1;
}

.top-box-foreground.show {
    display: block;
    position: fixed;
}