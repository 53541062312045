/* Menu Section  */

.menu-section {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background: #00000038;
    transition: all 0.5s ease;
}

.top-header {
    line-height: 38px;
    height: 39px;
    border-bottom: 1px solid #0000001a;
    overflow: hidden;
    transition: all 0.5s ease;
}

.social-links a {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 30px;
}

.social-links a:hover {
    color: #ff0102;
}
.helpline-box {
    float: right;
}
.helpline {
    color: #fff;
    font-family: "Heebo";
    float: right;

}

.menu-header {
    border-bottom: 1px solid #4a4a4a26;
    transition: all 0.5s ease;
}

.brand-logo {
    height: 50px;
    line-height: 100px;
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
}

.search-box {
    width: 350px;
    margin: 21px 0;
    float: left;
}

.brand-logo {
    /* background: #fff; */
}

.search-form-control {
    background-color: #ffffff99;
    border-color: #848282;
    transition: all 0.5s ease;
}

.search-form-control:focus {
    box-shadow: none;
}

.btn-search {
    color: #ffffff;
    background: #666;
    border: 0;
    transition: all 0.5s ease;
}

.obj-focused .search-form-control  {
    border-color: #e91818 !important;
    background-color: #fff !important;
}

.obj-focused >span {
    background-color: #e91818!important;
}


/*  Menu White Position Style */

.white .top-header {
    height: 0;
    border-bottom: 1px solid #eee;
    background: #fff;
}

.white .menu-header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.white .social-links a {
    color: #666;
}

.white .social-links a:hover {
    color: #ff0102;
}

.white .helpline {
    color: #ff0102;
    float: right;
}

.white .primary-menu>ul>li>a {
    color: #333;
}

.white .search-form-control {
    background-color: "inherit";
    border-color: #ddd;
}

.white .top-options>.top-icon {
    color: #666;
    align-items: center;
}


/*MODAL FADE LEFT BOTTOM */

.modal.left {
    font-family: Kiron, serif;
  }
  
  .modal.left .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    min-width: 300px;
  }
  
  .modal.left .modal-dialog.modal-sm {
    max-width: 300px;
    height: 100vh;
  }
  
  .modal.left .modal-content {
    min-height: 100vh;
    border: 0;
    height: 100vh;
    display: block;
    border-radius: 0;
  }
  
  .modal.left .modal-header {
    height: 75px;
  }
  
  .modal.left .modal-body {
    height: calc(100vh - 58px);
    overflow: auto;
  }
  
  .mobile-menu {
    list-style: none !important;
    padding-left: 5px;
  }
  
  .mobile-menu-sub {
    list-style: none !important;
    padding-left: 10px;
  }
  
  .mobile-menu li {
    font-size: 20px;
    line-height: 39px;
    list-style: none !important;
  }
  
  .mobile-menu-sub li {
    font-size: 20px;
    list-style: none !important;
    padding-left: 1px;
    line-height: 20px;
  }
  
  .mobile-menu a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
  
  .mobile-menu-sub a {
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
  .link-area>button{
      border: none;
      background: none;
      margin-left: 10px;
      color: #818181;

  }
/* Position Style */

.position-lower .top-header {
    height: 39px;
}
.sidebar-menu-tab .nav-tabs {
    border-bottom: none!important
}
.sidebar-menu-tab .nav-tabs .nav-link.active {
    border-color: transparent!important;
    color: #000000!important;
}
.sidebar-menu-tab>ul>li>button{
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.3px;
    text-transform: uppercase;
    color: #B3B3B3!important;
}
@media (max-width: 576px) {
    .top-header{
        display: none;
    }
}