
/* Slider Section */

.slider-section {
    overflow-x: hidden;
    background: #f3f3f3;
}

.slider-section .slick-prev {
    left: 30px;
    z-index: 1;
}

.slider-section .slick-prev::before {
    font-size: 50px;
}

.slider-section .slick-next {
    right: 60px;
    z-index: 1;
}

.slider-section .slick-next::before {
    font-size: 50px;
}
.slider-section-mobile {
   display: none;
 }
@media (max-width: 576px) {

.slick-slide img {
    height: 100%!important;
    object-fit: cover!important;
    object-position: top!important;
}
.slider-section {
   display: none;
}
.slider-section-mobile {
    overflow-x: hidden;
    background: #f3f3f3;
    display: block;
 }
 .slider-section-mobile .slick-prev {
    left: 30px;
    z-index: 1;
}

.slider-section-mobile .slick-prev::before {
    font-size: 50px;
}

.slider-section-mobile .slick-next {
    right: 60px;
    z-index: 1;
}

.slider-section-mobile .slick-next::before {
    font-size: 50px;
}
}