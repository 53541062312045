
/* Page Section */

.PageSection {
    background-color: #f3f3f3;
    padding-top: 80px;
}

.PageColI5 {
    flex: 33.33%;
    max-width: 33.33%;
}

.PageTitleText {
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
    display: inline-block;
}

.PageTitleText>span {
    border-bottom: 3px solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

.PageCard {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
}

.PageCard:hover {
    box-shadow: 0 0 6px 6px #77777760;
}

.PageCardBody {
    padding: 0.5rem;
}

.page-banner {
    height: 65px;
    text-align: center;
    padding: 10px;
}

.page-banner>h5 {
    text-align: center;
    margin: auto;
    padding-top: 40px;
}

.PageBrdCmb {
    background-color: transparent;
    border: 1px solid #e3dede;
}

.page-brand {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}


.page-brand >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-brand>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-brand>ul>li>label {
    padding-right: 15px;
}

.page-service {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}

.pageFilter {
    margin: 10px;
}

.page-filter-option {
    width: 100%;
}
.page-filter-view{
    background: #FFF;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #9993;
    margin-bottom: 20px;
}
.page-filter-option-table {
    padding: 5px !important;
    border: 1px solid #c2bdbd;
    vertical-align: middle;
}

.pageFilterShow {
    padding-left: 10px;
    height: 50px;
    width: 100%;
}

.pageFilterShowTag {
    margin-right: 8px !important;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
}

.pageFilterShowSearchTag {
    padding: 4px 8px;
    border: 1px solid hsl(0, 0%, 90%);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
    color: hsl(0, 0%, 20%);
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 12px;
    font-weight: 500;
}

.pageFilterShowSearchTagBtn {
    padding: 4px 8px;
    border: 1px solid hsl(0, 0%, 90%);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
    color: hsl(0, 0%, 20%);
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 12px;
    font-weight: 500;
}

.pageFilterShowClearText {
    color: hsl(0, 0%, 20%);
    font-size: 12px;
    margin: 0 8px;
    text-decoration: underline;
}

.page-service>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-service >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-size {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}

.page-size>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-size >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-size>ul>li>label {
    padding-right: 15px;
}
.page-service>ul>li>label {
    padding-right: 15px;
}

.PageColor {
    padding: 10px;
}
.PageColor >h6{
    font-size: 20px;
    font-weight: bold;
}
.PageColor>Button {
    margin: 10px;
    height: 30px;
    width: 30px;
}

.PagePrice {
    border-bottom: 1px solid #d7dfdf;
    padding-bottom: 20px;
}

.PagePrice >h6{
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;

}
.PagePrice>input {
    width: 75px;
    height: 30px;
    font-size: 12px;
    margin-right: 10px;
    margin-left:15px;
}

.PagePrice>Button {
    margin: 0;
    width: 40px;
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;
    float: right;
}

.category-product-sidebar{
    min-height: 100vh;
    background: #FFF;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #9993;
    padding: 10px 20px 20px 20px;
}
.category-product-sidebar-title{
    margin-bottom: 24px;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #00071B;
}
.category-product-sidebar-cat{
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E6E8;
    padding-bottom: 20px;
}
.category-product-sidebar-cat-hrader{
    display: flex;
    align-items: center;
    height: 20px;
    margin-bottom: 20px;
}
.category-product-sidebar-cat-hrader>p{
    width: 93%;
    height: 8px;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #00071B;

}
.category-product-sidebar-cat-hrader>button{
    background: none;
    border: none;
    float: right;
}
.category-product-sidebar-cat-detail{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.category-product-sidebar-cat-detail> .form-check{
    width: 40%;
}
.category-product-sidebar-cat-detail> .custom-radio{
    width: 49%;
}
.custom-checkbox>input{
    width: 15px!important;
    height: 15px!important;
    border: 1px solid #BDBEC3!important;
    box-sizing: border-box!important;
    border-radius: 0!important;
}
.custom-checkbox>input:checked{
    width: 15px!important;
    height: 15px!important;
    background-color: red!important;
    border-radius: 0!important;
    border: none!important;
    background-image: url('../images/IconCheckBoxCorrect.svg')!important;
    background-size: auto!important;
}
.custom-checkbox>input:focus{
    box-shadow: none!important
}
.custom-radio>input{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    margin-right: 10px;
}
.custom-radio>input:focus{
    box-shadow: none!important
}
.custom-checkbox>label{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.7;
   
}
.custom-radio>label{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.7;
   
}
.custom-radio>input:checked{
    width: 20px;
    height: 20px;
    background-color: #FE0002;
    border-radius: 10px;
    border: none;
    background-image: url('../images/IconCheckBoxCorrect.svg')!important;
    background-size: auto!important;
    background-repeat: no-repeat!important;
    background-position:center!important
}
/*Login Section*/
@media (max-width: 576px) {
    .PageSection{
        padding-top: 35px;
    }
}