.custom-registration-content .custom-input {
    height: 50px;
    font-size: 14px;
}

.custom-registration-content .form-label {
    margin-bottom: 3px;
}

.custom-registration-content .custom-login-btn,
.custom-registration-content .custom-login-btn-google,
.custom-registration-content .custom-login-btn-facebook,
.custom-registration-content .custom-login-btn-twitter {
    height: 40px;
}