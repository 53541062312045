/*User Side Bar Section*/


/* .user-operation {
    height: 80px;
    float: right;
    transition: all 0.5s ease;
}

.user-operation:hover {
    background-color: #00000015;
} */

.user-operation:hover .user-option {
    /* display: block; */
    z-index: 2;
    opacity: 1;
    transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 5px) !important;
    visibility: visible;
}

.user-operation>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 47px;
    cursor: pointer;
    margin: 0 15px;
    height: 100%;
    color: #fff;
    opacity: 1;
}

.user-operation-icon {
    margin: 0 auto;
    font-size: 23px;
}

.user-option {
    opacity: 0;
    display: block;
    position: absolute;
    z-index: -1;
    background-color: #fff;
    color: black;
    width: 250px;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);
    border-radius: 0.35rem;
    left: 488px;
    top: 48px;
    transform: perspective(999px) rotateX(-90deg) translateZ(0) translate3d(0, 37px, 0) !important;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    padding: 15px;
}

.user-option:before {
    content: "";
    position: absolute;
    top: -13px;
    left: 110px;
    right: auto;
    transition: top 0.35s ease;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
}


/* Profile sidebar */

.profile-sidebar {
    padding: 20px 0 10px 0;
    background: #fff;
}

.profile-userpic img {
    float: none;
    margin: 0 auto;
    width: 25%;
    height: 25%;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.profile-usertitle {
    text-align: center;
    margin-top: 20px;
}

.profile-usertitle-name {
    color: #5a7391;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}

.profile-usertitle-job {
    text-transform: uppercase;
    color: #FF0000;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
}

.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}

.profile-userbuttons .btn {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
    margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
    margin-right: 0px;
}

.profile-usermenu {
    margin-top: 30px;
}

.profile-usermenu>ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.profile-usermenu>ul>li {
    line-height: 45px;
    margin: 2px 20px;
    padding: 0px 15px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 3px;
    transition: all 0.5s;
}

.profile-usermenu>ul>li:hover {
    background: #eee;
}

.profile-usermenu>ul>li:last-child {
    border-bottom: none;
}

.profile-usermenu>ul>li>a {
    color: #373737;
    display: block;
}

.profile-usermenu>ul>li:hover>a {
    color: #ff0000b4;
}

.profile-usermenu>ul>li>a>span {
    padding-left: 30px;
}

.profile-user-circle {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background: #FF0000;
    margin: auto !important;
    align-items: center;
    justify-content: center;
    display: flex;
}

.profile-user-circle>img {
    
    border-radius: 50%;
    height: 100%;
    width: 100%;
}
.profile-user-circle>span {
    font-size: 20px !important;
}

.profile-content {
    padding: 20px;
    background: #fff;
    min-height: 460px;
}