.modal-classic {
    display: block;
    position: fixed;
    background-color: #000000cc;
    z-index: 1018;
    width: 100%;
    height: 100%;
}

.modal-classic .classic-main {
    display: block;
    position: fixed;
    width: 480px;
    background: #fff;
    border-radius: 40px;
    padding: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal-classic .classic-header {}

.modal-classic .classic-body {}

.modal-classic .classic-footer {}

.modal-classic .classic-exit {
    position: absolute;
    border: 0px solid;
    padding: 0;
    background: none;
    right: 42px;
}

.modal-classic .classic-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 44px;
    padding-top: 30px;
    text-align: center;
    font-family: "Jost", sans-serif;
}

.classic-btn {
    border: 0px solid;
    background-color: transparent;
    padding: 0;
    opacity: 0.7;
}

.classic-btn:hover {
    opacity: 1;
}

.login-steps {}

.input-area {
    margin-top: 25px;
}

.input-area .label {
    font-weight: 400;
    font-family: "Jost", sans-serif;
    margin-bottom: 9px;
    color: 1px solid rgb(17 17 17 / 70%);
}

.input-area .input {
    border: 1px solid rgb(17 17 17 / 20%);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.input-area .input input {
    border: 0px;
    width: 100%;
    padding-left: 20px;
    height: 50px;
    outline: none;
}

.input-area .input .post-icon {
    display: none;
    position: absolute;
    right: 15px;
    top: 15px;
}

.input-area.right {
    text-align: right;
}

.input-area .input.otp {
    border: 0px solid;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.input-area .input.otp input {
    border-radius: 12px;
    border: 1px solid rgb(17 17 17 / 20%);
    width: 50px;
    padding: 0;
    height: 50px;
    outline: none;
    text-align: center;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    font-size: 25px;
}