/*Footer Section*/

.footer-section {
    background: black;
    min-height: 300px;
    padding-top: 10px;
    display: block;
}
.footer-section-auto-height{
    display: none;
}
.footer-section-item {
    padding-top: 10px;
    padding-bottom: 30px;
}

.footer-section-logo {
    height: 50px;
    line-height: 100px;
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
}

.footer-section-address {
    color: white;
    padding-right: 65px;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #ffffff;
    opacity: 0.8;
}

.footer-section-quick-link {
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
    color: white;
}

.footer-section-quick-menu {
    color: white;
    float: left;
}

.footer-section-quick-menu>ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-section-quick-menu>ul>li>a {
    text-decoration: none;
    color: #ffffff;
    opacity: 0.8;
    line-height: 30px;
}

.footer-section-social-link {
    margin-right: 15px;
    float: left;
    color: white;
}

.footer-section-search-box {
    width: 350px;
    margin: 21px 0;
    float: left;
}

.footer-section-social-link-item {
    color: #666;
}

.footer-section-social-link-item a {
    color: #666;
    display: inline-block;
    width: 50px;
    font-size: 30px;
}

.footer-section-social-link-item a:hover {
    color: #ff0102;
}

.footer-section-copy-right {
    color: white;
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid #262626;
    vertical-align: bottom;
}

.footer-subscribe-box {
    width: 350px;
    margin: 21px 0;
    float: left;
}

.subscribe-form-control {
    background-color: #ffffff99;
    border-color: #848282;
    transition: all 0.5s ease;
}

.subscribe-obj-focused .subscribe-form-control {
    border-color: #e91818 !important;
    background-color: #fff !important;
}

.subscribe-obj-focused .subscribe-btn-search {
    background-color: #e91818;
}

.footer-section-mobile {
    /* display: none !important; */
}

.footer-section-news-divider {
    width: 60px;
    height: 2px;
    background: #ffffff;
    margin-bottom: 25px;
}

.footer-section-news>h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    color: #ffffff;
}

.footer-section-news>p {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    opacity: 0.8;
}

.footer-section-subscribe-input-group {
    width: 82%;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
}

.footer-section-subscribe-input-group>input {
    background: none;
    border: none;
    width: 86%;
    padding-left: 20px;
    outline: none !important;
    color: #ffffff;
    opacity: 0.7;
}

.footer-section-subscribe-input-group>button {
    width: 40px;
    height: 40px;
    background: red;
    border: none;
    margin: auto;
    outline: none;
}

.footer-section-contact-list {
    display: flex;
}

.footer-section-contact-list>button {
    height: 36px;
    border: none;
    margin-right: 14px;
    color: white;
    background: none;
}

.footer-section-contact-list>a {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    opacity: 0.8;
    text-decoration: none;
}
.footer-mobile {
    display: none;
}
.footer-navigation{
    height: 70px;
    width: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-navigation ul{
    width: 90%;
    display: flex;
    /* align-items: center;
    justify-content: center; */
}
.footer-navigation ul li{
    list-style: none;
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 1;
}
.footer-navigation ul li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
}
.footer-navigation ul li a .icon{
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    transition: 0.5s;
    color: #000000;
}
.footer-navigation ul li.active a .icon{
    transform: translateY(-35px)
}
.footer-navigation ul li a .text{
    position: absolute;
    font-size: 0.75em;
    transition: 0.5s;
    color: #000000;
    opacity: 0;
    transform: translateY(20px);
}
.footer-navigation ul li.active a .text{
    opacity: 1;
    transform: translateY(10px);
}
.footer-indicator{
    position: absolute;
    top: -50%;
    width: 70px;
    height: 70px;
    background-color: red;
    border-radius: 50%;
    border: 6px solid black;
    transition: 0.5s;
}
.footer-indicator::before{
    content: '';
    position: absolute;
    top: 50%;
    left: -23px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -10px 0 0 black;
}
.footer-indicator::after{
    content: '';
    position: absolute;
    top: 50%;
    right: -23px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -10px 0 0 black;
}
.footer-navigation ul li:nth-child(1).active ~ .footer-indicator{
    transform: translateX(calc(70px * 0));
}

.footer-navigation ul li:nth-child(4).active ~ .footer-indicator{
    transform: translateX(calc(70px * 3));
}

.footer-navigation ul li:nth-child(2).active ~ .footer-indicator{
    transform: translateX(calc(70px * 1));
}

.footer-navigation ul li:nth-child(3).active ~ .footer-indicator{
    transform: translateX(calc(70px * 2));
}

@media (max-width: 576px) {
    .footer-section{
        display: none;
    }
    .footer-section-auto-height{
        height: 45px;
        display: block;
    }
    .footer-mobile {
        width: 100%;
        height: 60px;
        background: #ffffff;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
        position: fixed;
        bottom: 0;
        display: block;

    }
    .footer-mobile-menu {
        padding-top: 12px;
    }
    .footer-mobile-menu>ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        align-items: center;
    }
    .footer-mobile-menu>ul>li {
        display: inline-block;
        position: relative;
        text-align: center;
        width: 25%;
    }
    .footer-mobile-menu>ul>li>a>img {
        margin-bottom: 5px;
    }
    .footer-mobile-home-icon {
        background-image: url("../images/IconFHome.svg");
        background-position: center;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
    }
    .footer-mobile-noti-icon {
        background-image: url("../images/IconFNoti.svg");
        background-position: center;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
    }
    .footer-mobile-cart-icon {
        background-image: url("../images/IconFCart.svg");
        background-position: center;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
    }
    .footer-mobile-pro-icon {
        background-image: url("../images/IconFProfile.svg");
        background-position: center;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
    }
    .footer-mobile-menu>ul>li>a>div {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        opacity: 0.7;
    }
    .footer-mobile-cart-item {
        position: absolute;
        background: #ff0000;
        color: #fff;
        font-weight: 400;
        right: 0px;
        text-align: center;
        border: 3px solid #ff0000;
        font-family: "jost";
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        right: 22px;
        top: -7px;
        font-size: 9px;
        width: 16px;
        height: 16px;
    }
}